.business {
	// display: flex;
	// align-items: flex-start;
	// justify-content: flex-start;
	margin: 8px auto;
}

.businessCard {
	width: 100%;
	margin: 8px auto;
	background: #ffffff;
	padding: 24px;
	border-radius: 8px;

	.logoText {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #0a0a0a;
	}

	.imagePlaceholder {
		text-align: center;
		height: 100px;
		width: 100px;
		margin-top: 8px;
		margin-bottom: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		margin: 8px auto 0 auto;
	}
}

.uploadButton {
	background: #8E173E;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #ffffff;
	padding: 8px 0px;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	margin-top: 40px;

	& span {
		padding-left: 8px;
	}
}

.businessForm {
	width: 100% !important;
	margin-top: 20px;
	margin-right: auto;

	&>form {
		display: flex;
		flex-direction: column;

		&>div {
			&:not(:first-child) {
				margin-top: 27px;
			}
		}
	}
}

.form {
	width: 100%;
}

.subText {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #616161;
}

.topSections {
	display: flex;
	flex-direction: column;
	// justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: 20px;
	padding-right: 200px;

	.newInvoice,
	.stepButton {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #211f01;
		margin-bottom: 20px;
	}
}

.footButtons {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: row;
	margin-top: 20px;
	width: 100%;

	.backButton {
		background: #ededed;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.011em;
		color: #9e9e9e;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		border-radius: 4px;
		width: 74px;
		height: 40px;
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: center;

		cursor: pointer;
	}

	.nextButton {
		background: #8E173E;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.011em;
		color: #ffffff;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		border-radius: 4px;
		width: 74px;
		height: 40px;
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: center;
		cursor: pointer;
		margin-left: 16px;
	}

	.nextButtonDisabled {
		background: #ededed;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.011em;
		color: #9e9e9e;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		border-radius: 4px;
		width: 74px;
		height: 40px;
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: center;
		margin-left: 16px;
	}
}

.stepwrapper {}

.stepper {
	display: flex;
	align-items: center;
}

.circle {
	width: 16.67px;
	height: 16.67px;
	border-radius: 8.3px;
	background-color: #ffffff;
	border: 1px solid #8E173E;
}

.circleActive {
	width: 16.67px;
	height: 16.67px;
	border-radius: 8.3px;
	background-color: #8E173E;
	border: 1px solid #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.stepper_p {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	display: flex;
	align-items: center;
	color: #080803;
	margin-left: 9px;
	padding: 0;
	margin-bottom: 0;
}

@media screen and (max-width: 1440px) {
	.topSections {
		padding-right: 50px;
	}
}

@media screen and (max-width: 1250px) {
	.business {
		flex-direction: column;
	}

	.businessCard {
		width: 100%;
	}

	.inputflex {
		flex-direction: column;
	}
}