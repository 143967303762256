.wrapper {
  padding: 36px 40px;
  width: 100%;
}
.wrapperInner {
  display: flex;
  width: 100%;
}
.wrapperInnerLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e7f3ec;
  border-radius: 14px;
  margin-right: 29px;
  width: 94px;
  height: 26px;
  cursor: pointer;

  p {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    color: #272501;
    margin: 0;
    padding: 0;
    margin-left: 10px;
  }
}
.wrapperInnerRight {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  // height: 80vh;
  position: relative;
}

.wrapperInnerRightContent {
  // height: 60vh;
  overflow: auto;
}
.wrapperInnerEmsaContent {
  margin-top: 10px;
  // border: 2px solid #a5a3a3;
  height: fit-content;
  overflow: auto;
}
.iframeBox{
  // border: 1px solid red;
  height: 70vh;
  margin: 10px 0px;
}
.wrapperh2 {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #8E173E;
}
.wrapperp {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #4b492b;
  margin-bottom: 35px;
  width: 567px;
}
//STOPS
.inputwrapper {
  padding: 25px 33px;
  max-width: 1000px;
  min-width: 300px;
  background: #ffffff;
  border-radius: 8px;
  p {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    text-align: justify;
    color: #272501;
  }

  h3 {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #070602;
    margin-top: 30px;
  }
}
.inputwrapperEmsa {
  margin-top: 20px;
  padding: 25px 33px;
  max-width: 1000px;
  min-width: 300px;
  background: #ffffff;
  border-radius: 8px;
  // border: 4px solid blue;
  p {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    text-align: justify;
    color: #272501;
  }

  h3 {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #070602;
    margin-top: 30px;
  }
}
.submitHandler {
  width: 85px;
  height: 40px;
  background: #8E173E;
  border-radius: 4px;
  outline: none;
  border: none;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  &:disabled {
    background: #b98055;
  }
}
.flexInput {
  width: 100%;
  display: flex;
  align-items: center;
}

.input {
  height: 54px;
  background: #ffffff;
  border: 1px solid rgba(43, 149, 88, 0.2);
  border-radius: 7px;
}

.buttonflex {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.cancel {
  width: 85px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  outline: none;
  border: 1px solid #8E173E;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #8E173E;
  margin-right: 11px;

  :disabled {
    background: #a5a3a3;
    color: #25392d;
  }
}

.radiowrapper {
  background: #f1f8f4;
  padding: 19px 23px;
  margin-bottom: 15px;

  p {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: #070602;
    margin-bottom: 15px;
  }
}

.scrollBtn{
  background: #8E173E;
  border-radius: 20px;
  color: white;
  padding: 6px 12px;
  position: absolute;
  bottom: 10px;
  left: 20px;
}

/*Mobile View */
@media (max-width: 768px){
	.wrapper{
		padding: 2px 3px;
	}
	.inputwrapper {
		width: 100%;
	}
	.wrapperInner {
		flex-direction: column;
	}
	.wrapperInnerLeft {
		background: #8E173E;
		color: white;
		width: fit-content;
		padding: 4px 14px;
		cursor: pointer;
		p{color: white;}
		margin-bottom: 16px;
	}
	.wrapperp{
		width: auto;
	}
  .buttonflex{
    justify-content: center;
  }
}
