.signInBody {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 15px;
	flex-direction: column;
}

.businessForm {
	width: 700px;
	margin-top: 20px;
}

.form {
	width: 100%;
}

.signIn {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
}

.signInButton {
	background: #8E173E;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	width: 100%;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #ffffff;
	padding: 12.5px 0px;
	cursor: pointer;

	& span {
		padding-left: 10px;
	}
}

.signInButtonDisabled {
	background: #ededed;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	width: 100%;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #9e9e9e;
	padding: 12.5px 0px;
	cursor: pointer;

	& span {
		padding-left: 10px;
	}
}

.forgotPassword {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #8E173E;
	margin-top: 20px;
	cursor: pointer;
}

.terms_and_condition {
	a {
		color: #8E173E;
		font-weight: bold;
	}

}

.businessInfo {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	margin-top: 30px;
}

.businessType {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 20px;
	display: flex;
	align-items: center;
	letter-spacing: -0.006em;
	color: #0a0a0a;
	margin-top: 30px;
}

.newAccount {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	margin-top: 30px;

	.noAccount {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.011em;
		color: #424242;
	}

	.create {
		background: #8E173E;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		width: 102px;
		height: 40px;
		margin-left: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.011em;
		color: #ffffff;
		cursor: pointer;

		& span {
			padding-left: 10px;
		}
	}
}

.radioSelection {
	display: flex;
	flex-direction: row;
	align-items: center;

	.radioLabel {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 20px;
		display: flex;
		align-items: center;
		letter-spacing: -0.006em;
		color: #424242;
	}
}

.sidebarFooter {
	position: fixed;
	bottom: 0;
	left: 0;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;
	letter-spacing: -0.006em;
	color: #424242;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px;
}

@media only screen and (max-width: 1200px) and (min-width: 601px) {
	.signinForm {
		width: 80vw;
	}
}

@media (max-width: 600px) {
	.signinForm {
		width: 80vw;
	}

	.newAccount {
		display: block;
		text-align: center;
	}
}

.inputLabel {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */

	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.006em;

	/* Neutral/90 */

	color: #424242;
}

.phoneInput {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;
	width: 100%;
}

//NEW
//NEW DESIGNS

.signWrapper {
	display: flex;
	height: 100vh;
	overflow: hidden;
}

.leftwrapper {
	flex: 1;
	padding: 30px;
	overflow-y: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	/* Firefox */
}

.leftwrapper::-webkit-scrollbar {
	display: none;
}

.rightwrapper {
	flex: 1;
}

.rightwrapper_img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.image {
	margin-bottom: 10px;

	img {
		width: 150px;
	}
}

.formwrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 106px;
}

.form_heading {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;
	text-align: center;
	color: #272501;
	margin-bottom: 10px;
}

.form_p {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #858481;
}

.businessForm {
	padding: 0 140px;
	width: 100%;
}

.form {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.signInButton {
	max-width: 441px;
	width: 100%;
	height: 56px;
	background: #8E173E;
	border-radius: 4px;
	outline: none;
	text-align: center;
	color: white;
	border-radius: 4px;
	border: none;
	margin: 0 auto;
	margin-top: 36px;
}

.forgetPassword {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	text-align: right;
	color: #272501;
}

.createuser {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #272501;
	margin-top: 118px;
}

.forgetPasswordspan {
	color: #8E173E;
	cursor: pointer;
}

.nameflex {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.buttonNext {
	height: 56px;
	width: 100%;
	outline: none;
	border: none;
	background: #8E173E;
	border-radius: 4px;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #ffffff;
	margin-top: 20px;

	&:disabled {
		opacity: .5;
		background: #8E173E;
	}
}

.flexInput {
	width: 100%;
	display: flex;
	align-items: center;
}

.input {
	height: 54px;
	background: #ffffff;
	border: 1px solid rgba(25, 31, 27, 0.2);
	border-radius: 7px;
}

@media only screen and (max-width: 1100px) {
	.signinForm {
		margin: 0 auto;
	}

	.rightwrapper {
		display: none;
	}
}

@media only screen and (max-width: 750px) {
	.signinForm {
		margin: 0 auto;
	}

	.businessForm {
		padding: 0 40px;
	}
}

@media only screen and (max-width: 600px) {
	.businessForm {
		padding: 0 10px;
	}
}