.declined {
  background: #ffecec;
  border-radius: 400px;
  width: max-content;
  padding: 4px 12px;
  // height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: small;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.006em;
  color: #e00000;
  text-transform: capitalize;
}

.processing {
  border-radius: 400px;
  width: fit-content;
  padding: 4px 12px;
  // height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff7ed;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: small;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.006em;
  color: #9e5400;
  text-transform: capitalize;
}

.completed {
  border-radius: 400px;
  width: fit-content;
  padding: 4px 12px;
  // height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ebf5f0;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: small;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.006em;
  color: green;
  text-transform: capitalize;
}

.not {
  border-radius: 400px;
  width: fit-content;
  padding: 4px 12px;
  // height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: indigo;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: small;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.006em;
  color: white;
  text-transform: capitalize;
}

.new {
  border-radius: 400px;
  width: fit-content;
  padding: 4px 12px;
  // height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ebf5f0;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: small;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.006em;
  color: rgb(37, 37, 222);
  text-transform: capitalize;
}
