.pageTop {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 16px;
	margin-bottom: 24px;
}

.transactions {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
}

.loading {
	font-size: 20px;
	text-align: center;
	padding: 30px auto;
}

.actions {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
}

.topUp {
	background: #8E173E;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	width: 103px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #ffffff;
	cursor: pointer;

	.topIcon {
		margin-right: 8px;
	}
}

.viewBalance {
	width: 206px;
	height: 40px;
	background: #edfaf1;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #8E173E;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 16px;
	cursor: pointer;

	.topIcon {
		margin-right: 8px;
	}
}

.balanceCardHeader {
	display: flex;
	align-content: center;
	justify-content: space-between;
	padding: 10px 10px 0px 0;

	p {
		height: 100%;
		display: flex;
	}

	.balanceCardHeaderButton {
		background: #8E173E;
		color: white;

		&:disabled {
			cursor: disabled;
		}
	}
}

.detailBox {
	background: white;
	margin-top: 24px;
	border-radius: 5px;
}

.detailh3 {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #0a0903;
	padding: 14px 24px;
}

.customerInfo {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	width: 50%;
	justify-content: flex-start;
	padding: 34px 0px;
}

.detailsValue {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 700;
	font-size: 10px;
	line-height: 16px;
	letter-spacing: 0.01em;
	text-transform: uppercase;
	color: #5c5b57;
}

.detailsKey {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #0a0903;
}