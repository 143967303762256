.transactionContent {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 40px;
}

.transactionDetails {
	display: flex;
	flex-direction: column;
	.transactions {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #000000;
	}
	.flow {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.006em;
		color: #616161;
	}
}

.timeLine {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.week {
		background: #ffffff;
		border: 1px solid #b6dbc1;
		box-sizing: border-box;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		height: 40px;
		width: 110px;
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 20px;
		text-align: center;
		letter-spacing: -0.006em;
		color: #424242;
		display: flex;
		align-items: center;
		justify-content: center;
		.dropArrow {
			padding-left: 4px;
		}
	}
}

.total {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	margin: 30px 0px;

	.inflow {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		text-align: center;
		letter-spacing: -0.006em;
		color: #616161;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		.blue {
			width: 16px;
			height: 4px;
			background: #0c73b8;
			padding-right: 4px;
		}
		& span {
			padding-left: 4px;
		}
	}
	.outflow {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		text-align: center;
		letter-spacing: -0.006em;
		color: #616161;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-left: 16px;
		.orange {
			width: 16px;
			height: 4px;
			background: #ff7e35;
			padding-right: 4px;
		}
		& span {
			padding-left: 4px;
		}
	}
}

.chartGraph {
	height: 500px;
	margin-top: 15px;
	background: #ffffff;
	border: 0.5px solid #b98055;
	border-radius: 5px;
}
