// .wrapper {
// 	padding: '0 40px';
// }

// @media only screen and (min-width: 1500px) {
// 	.wrapper {
// 		padding: 0 10px 0 40px;
// 	}
// }
.container{
	background: white;
	display: flex !important;
	flex-direction: column;
	justify-content: space-between !important;
	width: 100% !important;
	height: max-content !important;
}
.banner {
	background-color: #ffcc00d6;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4px;
	box-sizing: border-box;
	border-radius: 20px;
}
  
.banner p {
	margin: 0;
	font-weight: 500;
	font-size: large;
	text-align: center;
	line-height: 1.2; /* Controls spacing inside */
}
  
.wrapper {
	background: white;
	display: flex !important;
	justify-content: space-between !important;
	width: 100% !important;
	height: max-content !important;
	padding: 10px 0;
  }
.notificationTop {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	.messageFrom {
		font-size: 17px;
		line-height: 24px;
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 700;
	}

	.date {
		font-size: 15px;
		line-height: 24px;
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		color: #767373;
	}
}

.messageText {
	font-size: 15px;
	line-height: 24px;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	color: #767373;
}

.switchStatement {
	display: flex;
	justify-content: center;
	align-items: center;
}

.switchText {
	font-size: 12px;
	line-height: 24px;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	color: #767373;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 10px;
}

.navigationBack {
	display: flex;
	align-items: center;
}


/*Mobile View */
@media (max-width: 640px) {
	.wrapper{
		display: "flex";
		justify-content: "space-between";
		width: "100%";
		height: fit-content;
		flex-direction: column;
	}
	.switchText {
		font-size: 12px;
		line-height: 14px;
		padding-top: 2px;
	}
	.banner {
		padding: 8px;
	}
	.banner p {
		font-size: medium;
	}
}

/* Adjustments for phones */
@media (max-width: 480px) { 
	.banner {
		padding: 5px;
	}
	.banner p {
		font-size: small;
	}
}
  