$primary-color: #8e183e;
$tabBorderRadius: 0.15rem;

.tabsContainer {
  .tabs {
    display: flex;
    border-radius: 0.5rem;

    .tab {
      padding: 10px 20px;
      // margin-right: 4px;
      background-color: white;
      border: none;
      cursor: pointer;
      transition: all 0.3s ease;
      border-right: 0.25px solid #ccc;
      background-color: #e5e7eb;
      color: #6b7280;
      border-bottom: 4px solid transparent;

      &:first-child {
        border-top-left-radius: $tabBorderRadius;
      }
      &:last-child {
        border-top-right-radius: $tabBorderRadius;
        border-right: none;
      }

      &.active {
        border-bottom: 4px solid $primary-color;
        background-color: #fff;
        color: $primary-color;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .tabContent {
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
  }
}
