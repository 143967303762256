.wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding-top: 36px;
	margin: 0 auto;
}

.wrapperh2 {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	text-align: center;
	color: #8E173E;
}

.wrapperp {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #4b492b;
	margin-bottom: 35px;
}

.wrapperbox {
	padding: 25px 33px;
	width: 539px;
	background: #ffffff;
	border-radius: 8px;
}

.submitHandler {
	padding: 16px 134px;
	width: 100%;
	height: 56px;
	background: #8E173E;
	border-radius: 4px;
	outline: none;
	border: none;
	color: white;

	&:disabled {
		opacity: .5;
		background: #8E173E;
	}
}

.flexInput {
	width: 100%;
	display: flex;
	align-items: center;
}

.input {
	height: 54px;
	background: #ffffff;
	border: 1px solid rgba(43, 149, 88, 0.2);
	border-radius: 7px;
}

.businessNameh4 {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: #070602;
	margin-bottom: 28px;
}

.businessNameBox {
	padding: 14px 13px;
	height: 69px;
	background: #ffffff;
	border: 1px solid rgba(43, 149, 88, 0.2);
	border-radius: 7px;
	display: flex;
	align-items: center;
	margin-bottom: 14px;
	color: #020201;
	cursor: pointer;
}

.boxright {
	flex: 1;

	h3 {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		margin: 0;
		padding: 0;
	}

	p {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		margin: 0;
		padding: 0;
	}
}

.boxleft {
	margin-right: 13px;
}


.formSelect {
	width: 100%;
	padding: 15px 10px;
	border-radius: 3%;
	border-color: rgba(128, 128, 128, 0.638);
}

/*Mobile View */
@media (max-width: 768px) {
	.wrapperbox {
		width: 100%;
		padding: 20px;
	}

	.submitHandler {
		padding: 14px 0px;
		width: 100%;
		height: fit-content;
	}

	.wrapperh2 {
		font-size: 18px;
		line-height: 24px;
	}

	.wrapperp {
		font-size: 12px;
		line-height: 18px;
		margin-bottom: 25px;
	}

	.businessNameBox {
		flex-direction: column;
		align-items: flex-start;
		height: auto;
		padding: 14px;
	}

	.boxright h3 {
		font-size: 12px;
	}

	.boxright p {
		font-size: 10px;
	}

	.flexInput {
		flex-direction: column;
		align-items: flex-start;
	}

	.input {
		width: 100%;
	}

	.formSelect {
		width: 100%;
		padding: 12px 8px;
		border-radius: 5px;
	}
}