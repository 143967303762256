.modalBody {
  width: 500px;
  // border: 2px solid red;
  border-radius: 12px;
  padding: 10px;
  .background {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .modalHeader {
   background: #8E173E;
  }
}

.linkDetails {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: -0.011em;

  /* Neutral/10 */

  color: #ffffff;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  padding: 10px 24px;
  .modalDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    border-bottom: 1px solid #e0e0e0;
  }
  .modalDetails:nth-child(n-1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
  }
  .link {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.011em;
    color: #0a0a0a;
  }

  .content {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.011em;
    color: #424242;
  }
}

.inactive {
  background: #ffecec;
  border-radius: 400px;
  width: fit-content;
  padding: 4px 12px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.006em;
  color: #e00000;
  text-transform: capitalize;
}

.active {
  border-radius: 400px;
  width: fit-content;
  padding: 4px 12px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ebf5f0;
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.006em;
  color: #8E173E;
  text-transform: capitalize;
}

.qrcontainer{
	width: 80%;
	height: auto;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	background-color: #8E173E;
	color: white;
  }
  .qrbox{
	width: auto;
	height: auto;
	padding: 20px;
	border-radius: 20px;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  .iconBox{
	width: 80%;
	border: 1px solid #8E173E;
	display: flex;
	padding: 5px 0;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 30px;
	margin: 15px 0;
	background-color: white;
  }
  .qrIcon{
	height: 30px;
	width: 30px;
	margin: 0px 4px;
	object-fit: contain;
  }
  