.business{
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 8px auto;
}
.datePickerWrapper {
    width: 100%; // Wrapper takes full width
    display: block;
	z-index: 1000 !important;

    .react-datepicker__input-container {
        width: 100%; // Ensures input container expands
		z-index: 1000 !important;
    }
}
.react-datetime-picker__wrapper {
	border: none !important;
	}
.datePickerInput {
    width: 100%; // Makes input take full width
    height: 32px; // Adjust height for consistency
    border-radius: 4px;
    padding: 5px 12px; // Adds spacing inside the input
    font-size: 14px;
    box-sizing: border-box; // Includes padding and border in width
}

.rowContainer {
    display: flex;
    justify-content: space-between;
    gap: 20px; // Adds spacing between input fields
    margin-bottom: 20px;

    & > * {
        flex: 1; // Ensures equal spacing for input fields
        min-width: 0; // Prevents overflow
    }
}
.tableContainer {
	width: 100%;
	overflow-x: auto; // Makes the table responsive

	.itemTable {
		width: 100%;
		border-collapse: collapse;

		th,
		td {
			padding: 10px; // Adds spacing inside the cells
			text-align: start;
			font-family: 'Sarabun';
			font-size: 14px;
			

			input {
				width: 100%;
				padding: 6px;
				border: none; // Removes input borders
				// background: #f9f9f9;
				background: transparent; // Light background for inputs
				border-radius: 4px;
				outline: none;
			}
		}

		th {
			background-color: #8e173e;
			color: #ffffff;
			font-weight: 600;
			text-align: left; // Aligns headers to the left
			border-bottom: none; // Removes bottom border for headers
		}

		tr {
			background-color: #ffffff; // Keeps row background white
			border-bottom: 2px solid #BDBDBD; // Adds spacing between rows
		}
		tr:nth-child(even) {
			background-color: #ffffff; // Ensures no alternate row colors
		}

		tr:hover {
			background-color: #f8f8f8; // Light hover effect for better UX
		}
	}

	.removeButton {
		color: #e00000;
		background: none;
		border: none;
		cursor: pointer;
		font-size: 14px;
		text-decoration: underline;
	}

	.newItem {
		font-family: 'Sarabun';
		font-weight: 600;
		color: #8e173e;
		margin-top: 10px;
		cursor: pointer;
		text-align: left;
	}
}
.notesAndTotal {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: 20px;

	.notesSection {
		width: 60%; // Adjusts the left side width
	}
	.notesSection textarea,
	.notesSection input {
		text-align: start;
		width: 100%; // Forces the input to stretch full width
		height: 100px; // Adjusts the height to make it longer
		padding: 10px; // Adds internal spacing
		border: 1px solid #dcdcdc; // Light border for clarity
		border-radius: 4px; // Smooth edges
		background: #f9f9f9; // Light background for better readability
		font-family: 'Sarabun';
		font-size: 14px;
		color: #424242;
		outline: none; // Removes focus outline
	}
	.totalSection {
		width: 35%; // Adjusts the right side width
		background: #f9f9f9;
		padding: 15px 20px;
		border-radius: 8px;

		.subTotal,
		.taxTotal,
		.grandTotal {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;

			.text {
				font-family: 'Sarabun';
				font-size: 14px;
				color: #616161;
			}

			.amount {
				font-family: 'Sarabun';
				font-weight: 600;
				font-size: 14px;
				color: #0a0a0a;
			}
		}

		.grandTotal {
			.text {
				font-weight: 700;
				font-size: 16px;
			}

			.amount {
				font-size: 16px;
				font-weight: 700;
			}
		}

		.totalDivider {
			border: none;
			height: 1px;
			background: #bdbdbd;
			margin: 10px 0;
		}
	}

	@media screen and (max-width: 768px) {
		flex-direction: column;

		.notesSection,
		.totalSection {
			width: 100%;
		}
	}
}

.businessCard {
	// width: 885px;
	width: 100%;
	background: #ffffff;
	// border: 2px solid red;
	margin-bottom: 10px;
	padding: 24px;
	border-radius: 8px;
	.logoText {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #0a0a0a;
	}

	.imagePlaceholder {
		text-align: center;
		height: 100px;
		width: 100px;
		margin-top: 8px;
		margin-bottom: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		margin: 8px auto 0 auto;
	}
}
.uploadButton {
	background: #8E173E;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #ffffff;
	padding: 8px 0px;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	margin-top: 40px;
	& span {
		padding-left: 8px;
	}
}
.businessForm {
	width: 100% !important;
	margin-top: 20px;
	margin-right: auto;
	& > form {
		display: flex;
		flex-direction: column;
		& > div {
			&:not(:first-child) {
				margin-top: 27px;
			}
		}
	}
}
.form {
	width: 100%;
}
.modalBody {
	width: 444px;
	// border-radius: 12px;
	padding: 20p;
	// border: 1px solid red;
	min-height: 50vh;
	max-height: 90vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	
	.background {
	  padding: 16px 24px;
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	}
	.modalHeader {
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  padding: 20px 5px;
	}
  
	.modalContent {
	  padding: 16px 24px;
	  margin: 10px 0px;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  flex-direction: column;
	  .confirmation {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: x-large;
		line-height: 24px;
		display: flex;
		align-items: center;
		text-align: center;
		letter-spacing: -0.011em;
		color: #0a0a0a;
		margin-bottom: 16px;
	  }
	  .link {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		text-align: center;
		letter-spacing: -0.011em;
		color: #424242;
	  }
	}
  
	.modalFooter {
	  margin: 10px 0px;
	  padding: 16px 24px;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  background: transparent;
	  .cancel {
		margin: 0px 5px;
		height: fit-content;
		min-width: fit-content;
		text-align: center;
		width: 150px;
		background: #959595;
		color: white;
		border-radius: 8px;
		font-weight: 600;
		padding: 10px 20px;
		height: 45px;
		// width: 120px;
		border: none;
		cursor: pointer;
	  }
	  .cancel:hover{
		background: #8e173e;
	  }
	  .deactivate {
		margin: 0px 5px;
		height: fit-content;
		min-width: fit-content;
		text-align: center;
		width: 150px;
		border: 1px solid #8e173e;
		background: transparent;
		color: #8e173e;
		border-radius: 8px;
		font-weight: 600;
		padding: 10px 20px;
		height: 45px;
		// width: 120px;
		border: none;
		cursor: pointer;
	  
		&.disabledButton {
		background: #959595;
		cursor: not-allowed;
		}
	  
	  }
	}
  }
.subText {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #616161;
}

.topSections {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	width: 100%;
	.newInvoice,
	.stepButton {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #0a0a0a;
	}
}
.footButtons {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: row;
	margin-top: 20px;
	width: 100%;
	.backButton {
		background: #ededed;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.011em;
		color: #9e9e9e;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		border-radius: 4px;
		width: 74px;
		height: 40px;
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: center;

		cursor: pointer;
	}
	.nextButton {
		background: #8E173E;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.011em;
		color: #ffffff;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		border-radius: 4px;
		width: 115px;
		height: 40px;
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: center;
		cursor: pointer;
		margin-left: 16px;
	}
}

.inputLabel {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */

	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.006em;

	/* Neutral/90 */

	color: #424242;
}

.itemLabel {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	width: 100%;
	border-bottom: 1px solid #424242;
	padding: 10px;
}

.removeButton {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #e00000;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: flex-end;
	padding-top: 10px;
	cursor: pointer;
}

.newItem {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #8E173E;
	margin-top: 20px;
	cursor: pointer;
}

.subTotal {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 12px;
	border-bottom: 1px solid #e0e0e0;
	margin-top: 30px;
	.text,
	.amount {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #424242;
	}
}

.Total {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-top: 12px;
	.text,
	.amount {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #0a0a0a;
	}
}
textarea{
	background-color: transparent !important;
	border: none !important;
}

// Styles for download 
.downloadInvoiceinvoiceContainer {
	font-family: Arial, sans-serif;
	width: 800px;
	margin: auto;
	padding: 20px;
	border: 1px solid #ddd;
	background-color: #fff;
  
	.downloadInvoiceheader {
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
  
	  .downloadInvoicelogoImage {
		height: 50px;
	  }
  
	  .downloadInvoicemerchantInfo h1 {
		margin: 10px 0;
		font-size: large;
		// font-size: 24px;
	  }

	  .downloadInvoiceinvoiceDetails p {
		text-align: right;
	  }
	}
  
	.downloadInvoicebillTo {
	  display: flex;
	  justify-content: space-between;
	  margin: 20px 0;
  
	  h5 {
		margin: 0;
		color: #888;
	  }
	}
  
	.downloadInvoiceitems {
	  margin: 20px 0;
  
	  table {
		width: 100%;
		border-collapse: collapse;
  
		th, td {
		  text-align: left;
		  padding: 10px;
		  border-bottom: 1px solid #ddd;
		}
		td{
			color: #0a0a0a;
		}
		th {
		  background-color: #8E173E;
		  color: white;
		}
	  }
	}
  
	.downloadInvoicesummary {
	  display: flex;
	  justify-content: end;
	  align-items: end;
	  margin: 20px 0;
	  flex-direction: column;
  
	  div {
		text-align: right;
		margin-left: 20px;
	  }
	}
  
	.downloadInvoicefooter {
	  text-align: center;
	  margin-top: 20px;
	  display: flex;
	  flex-direction: row;
	  align-items: center;
	  justify-content: center;
	}
	.downloadInvoicefooter img{
		height: 30px;
	}
  }


@media screen and (max-width: 1250px) {
	.business {
		flex-direction: column;
	}
	.businessCard {
		width: 100%;
	}

	.inputflex {
		flex-direction: column;
	}
}

@media screen and (max-width: 640px) {
    .business {
        flex-direction: column;
        align-items: stretch;
    }

    .rowContainer {
        flex-direction: column; 
        gap: 10px; 
    }

    .tableContainer {
        overflow-x: auto; 
    }

    .notesAndTotal {
        flex-direction: column; 
        gap: 15px;

        .notesSection,
        .totalSection {
            width: 100%;
        }
    }

    .businessCard {
        width: 100%; 
        margin-bottom: 15px; 
        padding: 16px;
    }

    .uploadButton {
        flex-direction: column;
        gap: 8px;
    }

    .datePickerWrapper {
        width: 100%; 
    }

    .businessForm {
        & > form {
            & > div {
                margin-top: 15px;
            }
        }
    }
}

// Styles for download 
@media screen and (max-width: 640px) {
	.downloadInvoiceinvoiceContainer {
		width: 100%;
		font-size: small;
		padding: 5px;
	}
	.downloadInvoicebillTo {
		flex-direction: column;
	}
	.downloadInvoicefooter img{
		height: 100px;
	}
}