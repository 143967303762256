.business {
	width: 100%;
}

.info_wrapper {
	display: flex;
}

.business_status {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.info_word {
	width: 300px;

	h2 {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		color: #0a0903;
	}

	p {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: #4b492b;
	}
}

.info_data {
	flex: 1;
	background: #ffffff;
	border-radius: 8px;
	padding: 27px;
	margin-left: 108px;
}

.user_description {
	margin-top: 20px;
	display: flex;

	align-items: center;
}

.user_description_image {
	margin-right: 20px;
	// border-radius: 5%;

	img {
		border: 1px solid rgb(188, 188, 188);
		width: 70px;
		height: 70px;
		border-radius: 50%;
		object-fit: cover;
	}
}

.user_description_action {
	display: flex;
	align-items: center;
}

.user_description_action_item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
}

.icon_box {
	display: flex;
	align-items: center;
	margin-left: 40px;
}

.actions {
	display: flex;
	align-items: center;
}

.actions_h4 {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	color: #002841;
	padding: 0;
	margin: 0 10px;
}

.line {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 28px;
	border: 0.5px solid #d7e0eb;
	padding: 15px 0;
}

.profile {
	width: 507px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	overflow-y: scroll;
	margin: 8px auto;
}

.profileCard {
	width: 100%;
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	padding: 24px;

	.logoText {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #0a0a0a;
	}

	.imagePlaceholder {
		text-align: center;
		height: 100px;
		width: 100px;
		margin-top: 8px;
		margin-bottom: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		margin: 8px auto 0 auto;
	}
}

.businessCard {
	width: 100%;

	.logoText {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #0a0a0a;
	}

	.imagePlaceholder {
		text-align: center;
		height: 100px;
		width: 100px;
		margin-top: 8px;
		margin-bottom: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		margin: 8px auto 0 auto;
	}
}

.uploadButton {
	background: #8E173E;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #ffffff;
	padding: 8px 0px;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	margin: 0 5.5px;
	width: 121px;
	height: 40px;
}

.businessForm {
	width: 100% !important;
	margin-top: 20px;
	margin-right: auto;

	&>form {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 35px;

		&>div {
			&:last-of-type {
				grid-column-start: 1;
				grid-column-end: 3;
			}
		}
	}
}

.passwordForm {
	width: 100% !important;
	margin-top: 20px;
	margin-right: auto;

	&>form {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 35px;
	}
}

.form {
	width: 100%;
}

.subText {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #616161;
}

.buttonSubmit {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

@media screen and (max-width: 1300px) {
	.info_wrapper {
		flex-direction: column;
	}
}

@media screen and (max-width: 980px) {
	.info_data {
		margin-left: 0px;
	}

	.businessForm {
		&>form {
			display: flex;
			flex-direction: column;
		}
	}
}