.wrapper {
}
.actions {
	display: flex;
	gap: 20px;
	align-items: center;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.headerh3 {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #0a0903;
}
.headerbutton {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  width: 120px;
  height: 40px;
  background: #8e173e;
  border-radius: 4px;
  outline: none;
  border: none;
}

.grids {
  margin-top: 24px;
}

.words {
  margin-top: 24px;
  width: 761px;
  height: 218px;
  padding: 33px 30px;
  background: #ffffff;
  border-radius: 4px;
}

.wordsh5 {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #020201;
}

.wordsp {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6f6e56;
}

.wordsbutton {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  width: 132px;
  padding: 10px;
  background: #8e173e;
  border-radius: 4px;
  border: none;

  &:hover {
    color: #fff;
    opacity: 0.75;
  }
}

.modalwrapper {
  width: 518px;
  // height: 574px;
  background: #ffffff;
}
.modalhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 32px;
}
.modalheadh3 {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1a1901;
}
.modalcontent {
  padding: 34px 32px;
  width: 100%;
}
.modalcontentp {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272501;
}
.modalcontentbox {
  margin-top: 29px;
  padding: 17px 30px;
  background: #f5faf7;
  border-radius: 5px;
  margin-bottom: 60px;
}
.modalcontentboxflex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modalcontentboxp {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #272501;
}
.modalselect {
  width: 100%;
  margin-bottom: 21px;
}
.select {
  width: 100%;
}
.inputLabel {
}
.modalFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
}
.cancel {
  width: 84px;
  height: 40px;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #8e173e;
  margin-right: 16px;
  outline: none;
  border: 1px solid rgb(238, 238, 238);
  background: #ffffff;
  border-radius: 4px;
}
.fund {
  width: 75px;
  height: 40px;
  background: #8e173e;
  border-radius: 4px;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: none;
}

.input {
  width: 100%;
  margin-bottom: 29px;
}
