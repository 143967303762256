.routerContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0;
	margin: 0;
	background: #f8f8f8;
}

.switchContainer {
	margin: 0 40px;
	overflow-y: scroll;
}

.navContainer {
}

.wrapper {
	display: flex;
	position: relative;
	overflow-y: hidden;
	height: 100vh;
	width: 100%;
}

.sidedrawer {
	width: 250px;
	height: 100vh;
	border-right: 1px solid rgba(206, 206, 205, 0.4);
	overflow: hidden;
}
.sidedrawerColl {
	width: 78px;
	transition: all 250ms ease-in-out;
	overflow: hidden;
}

.tabContainer {
	// margin: 32px 40px 24px 40px;
	flex: 1;
	display: flex;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	flex-wrap: wrap;
}
.wrapperf {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #ffffff;
	margin-bottom: 20px;
	min-height: 104px;
	padding: 0px 20px;
}

.rolesButton {
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #8E173E;
	padding: 8px 0px;
	cursor: pointer;
	width: 173px;
	height: 40px;

	& span {
		padding-left: 10px;
	}
}

.activeMenuItem {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	display: flex;
	align-items: center;
	background-color: #fff;
	margin: 0 1rem;

	.activeItemColor {
		color: #0a0903;
		border-bottom: 3.5px solid #8E173E;
		border-radius: 2px;
		padding-top: 10px;
		padding-bottom: 10px;
		cursor: pointer;
	}
}
.subMenuItem {
	padding: 0.5rem;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #424242;
	margin-bottom: 5px;
}

.subMenuItem:hover {
	background: #edfaf1;
	border-radius: 6px;
	cursor: pointer;
}

// @media only screen and (max-width: 920px) {
// 	.switchContainer {
// 		margin-top: 1rem;
// 	}
// }

// @media (max-width: 10px) {
// 	.wrapperf {

// 	}

// }
