.uploadBox {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 4rem;
    border: 1.5px dashed #8e183e;
    flex-direction: column;
    border-radius: 4px;
    cursor: pointer;

    &.dragging {
      background-color: #f4e8ec;
    }

    p {
      font-size: small;
      font-weight: 500;

      span {
        color: #6b6b6b;
      }
    }

    img {
        height: 25px;
    }
  }