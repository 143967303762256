.wrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  button {
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    gap: 6px;
    color: #8e183e;

    &:disabled {
      color: #959595;
      cursor: not-allowed;
    }

    .icon {
      font-size: 1.25rem;
    }

    .directionText {
      display: none;
      @media (min-width: 768px) {
        display: inline;
      }
    }
  }

  p {
    margin: 0;
  }
}
