.menuBody {
  position: relative;
  .menuIcon {
    cursor: pointer;
  }
  .menuContent {
    background: #ffffff;
    border: 1px solid #ededed;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 2px;
    position: absolute;
    z-index: 9;
    padding: 15px 10px 5px 15px;
    right: 2rem;
    width: 258px;
  }
}

.modalBody {
  width: 444px;
  border-radius: 12px;
  .background {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .modalHeader {
    background: #8E173E;
  }
}

.linkDetails {
 font-family: 'Sarabun';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: -0.011em;

  /* Neutral/10 */

  color: #ffffff;
}

.modalContent {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 0 24px;
  .modalDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    border-bottom: 1px solid #e0e0e0;
  }
  .modalDetails:nth-child(n-1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
  }
  .link {
   font-family: 'Sarabun';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.011em;
    color: #0a0a0a;
  }

  .content {
   font-family: 'Sarabun';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.011em;
    color: #424242;
  }
}

.inactive {
  background: #ffecec;
  border-radius: 400px;
  width: fit-content;
  padding: 4px 12px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
 font-family: 'Sarabun';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.006em;
  color: #e00000;
  text-transform: capitalize;
}

.active {
  border-radius: 400px;
  width: fit-content;
  padding: 4px 12px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ebf5f0;
 font-family: 'Sarabun';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.006em;
  color: #8E173E;
  text-transform: capitalize;
}

.processing {
  border-radius: 400px;
  width: fit-content;
  padding: 4px 12px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff7ed;
 font-family: 'Sarabun';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.006em;
  color: #9e5400;
  text-transform: capitalize;
}
