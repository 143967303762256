.week {
	background: #ffffff;
	border: 1px solid #8E173E;
	box-sizing: border-box;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	height: 40px;
	width: 110px;
	font-family: 'Kumbh Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	text-align: center;
	letter-spacing: -0.006em;
	color: #424242;
	display: flex;
	align-items: center;
	justify-content: center;
	.dropArrow {
		padding-left: 4px;
	}
}

.exportMenu {
	position: relative;
}

.export {
	background: #ffffff;
	border: 1px solid #8E173E;
	box-sizing: border-box;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	height: 40px;
	width: 89px;
	font-family: 'Kumbh Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	text-align: center;
	letter-spacing: -0.006em;
	color: #424242;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 20px;
	cursor: pointer;
	.dropArrow {
		padding-left: 4px;
	}
}

.exportDropdown {
	width: 320px;
	background: #ffffff;
	border: 1px solid #ededed;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 10px;
	font-family: 'Kumbh Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #424242;
	right: 0rem;
	margin-top: 5px;
	position: absolute;
	z-index: 9;
}

.csv {
	padding-top: 16px;
	text-decoration: none;
	color: #424242;
}

.pageTop {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 16px;
	margin-bottom: 24px;
}

.transactions {
	font-family: 'Kumbh Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
}
.actions {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
}

.filter {
	background: #ffffff;
	border: 1px solid #8E173E;
	box-sizing: border-box;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	height: 40px;
	width: 107px;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	text-align: center;
	letter-spacing: -0.006em;
	color: #424242;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 20px;
	cursor: pointer;

	& > span {
		border: 1px solid #8E173E;
		height: 40px;
		border-right: 8px;
		margin-left: 19px;
		padding-top: 9px;
		padding-left: 9px;
	}

	.dropArrow {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	transition: all 0.3s ease; 

	&:hover {
		background-color: gainsboro; 
		border-color: #721031; 
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15); 
		.dropArrow {
			color: #ffffff;
		}
	}
}
