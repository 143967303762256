.rolesBody {
	width: 100%;
	display: flex;
	// align-items: baseline;
	justify-content: flex-start;
	flex-direction: row;

	.rolesHeader {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #0a0a0a;
	}

	.mt1 {
		margin-top: 1rem;
	}

	.mt2 {
		margin-top: 2rem;
	}
}

.roles {
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	padding: 24px;
	width: 682px;

	.permissionsList {
		margin-top: 5px;
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #424242;

		.permissionsItems {
			padding-top: 10px;
			padding-bottom: 10px;
			border-bottom: 1px solid #e0e0e0;
			display: flex;
			align-items: center;
		}

		.permissionsItemsTwo {
			padding-top: 10px;
			padding-bottom: 10px;
			display: flex;
			align-items: center;
		}

		.rolesLabel {
			width: 90%;
		}

		.mark {
			width: 10%;
			text-align: right;
		}
	}
}

.rolesInfo {
	// margin-top: 4px;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: -0.006em;
	color: #4b492b;
}

.rolesDescription {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #616161;
	// margin-top: 10px;
}

.rolesButton {
	background: #8E173E;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	width: 80%;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #ffffff;
	padding: 8px 0px;
	cursor: pointer;

	& span {
		padding-left: 10px;
	}
}

.editButton {
	background: #8E173E;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
	width: 117px;
	height: 40px;
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #ffffff;
	padding: 8px 0px;
	cursor: pointer;
	margin-top: 24px;

	& span {
		padding-left: 10px;
	}
}

.sidenav {
	width: 50%;
	z-index: 0;
	top: 150px;
	left: 100px;
	overflow-x: hidden;
	padding: 0px 0;

	.sidenavHeader {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #0a0a0a;
	}

	.sideItem {
		padding: 8px 0;
		text-decoration: none;
		display: block;
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */
		display: flex;
		align-items: center;
		letter-spacing: -0.011em;
	}
}

.sideItem:hover {
	color: #8E173E;
}

@media only screen and (max-width: 1200px) {
	.rolesBody {
		flex-direction: column;
	}
	.sidenav {
		margin-bottom: 20px;
	}
	.roles {
		width: 100%;
	}
}

// @media (max-width: 600px) {
// 	.rolesBody {
// 	}

// 	.sidenav {
// 	}
// }
