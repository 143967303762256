.filterSubheading {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #0a0a0a;
}

.filterBody {
  margin-top: 10px;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #424242;
}

.modalwrapper {
  width: 518px;
  // height: 574px;
  background: #ffffff;
}
.modalhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 32px;
}
.modalheadh3 {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1a1901;
}
.modalcontent {
  padding: 34px 32px;
  width: 100%;
}
.modalcontentp {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272501;
}
.modalcontentbox {
  margin-top: 29px;
  padding: 17px 30px;
  background: #f5faf7;
  border-radius: 5px;
  margin-bottom: 60px;
}
.modalcontentboxflex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modalcontentboxp {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #272501;
}
.modalselect {
  width: 100%;
  margin-bottom: 21px;
}
.select {
  width: 100%;
}
.inputLabel {
}
.modalFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
}
.cancel {
  width: 84px;
  height: 40px;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #8e173e;
  margin-right: 16px;
  outline: none;
  border: 1px solid rgb(238, 238, 238);
  background: #ffffff;
  border-radius: 4px;
}
.fund {
  width: 75px;
  height: 40px;
  background: #8e173e;
  border-radius: 4px;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: none;
}

.input {
  width: 100%;
  margin-bottom: 29px;
}

.transactionStatus {
  background-color: #009a491a;
  color: #009a49;
  font-size: 14px;
  padding: 4px 12px;
  font-weight: 400;
  text-transform: capitalize;
  border-radius: 10px;
}
