.tooltip-content {
  color: #000;
  font-size: 14px;
  padding: 5px;
}

.tooltip-icon {
  color: #8e183e;
}

.action-btn,
.action-btn-outlined {
  min-width: 150px;
}

.action-btn {
  background-color: #8e183e;
  padding: 8px;
  border-radius: 5px;
  outline: none;
  border: none;
  font-size: 0.85rem;
  color: #fff;
  transition: all 0.5s ease-in !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}
.action-btn:active {
  transform: scale(0.95) !important;
  padding: 5px;
}

.action-btn:hover {
  color: #fff;
  opacity: 0.85 !important;
}

.action-btn:disabled {
  background-color: #959595;
  border-color: #959595;
  color: #fff;
  cursor: not-allowed;
}

.action-btn-outlined {
  border: 1px solid #8e183e;
  background-color: transparent;
  padding: 8px;
  border-radius: 5px;
  outline: none;
  font-size: 0.85rem;
  color: #8e183e;
  transition: all 0.5s ease-in !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}
.action-btn-outlined:active {
  transform: scale(0.95) !important;
  padding: 5px;
  background-color: #8e183e;
  color: #fff;
}

.action-btn-outlined:hover {
  color: #fff;
  opacity: 0.85 !important;
  background-color: #8e183e;
}

.action-btn-outlined:disabled {
  background-color: #959595;
  border-color: #959595;
  color: #fff;
  cursor: not-allowed;
}

.action-btn-transparent {
  color: #8e173e;
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: 500;
  transition: all 0.25s ease-in;
}

.action-btn-transparent:hover {
  opacity: 0.85 !important;
}

.action-btn-transparent:active {
  font-size: 95%;
}

.checkbox {
  appearance: none;
  height: 1rem;
  aspect-ratio: 1;
  border: 1px solid #8e173e;
  position: relative;
  border-radius: 4px;
  cursor: pointer;
  background-size: 80%;
}

.checkbox-outlined:checked {
  background-color: transparent;

  /* Keep the checkmark */
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%238e173e"%3E%3Cpath d="M20.29 5.71l-11 11c-.18.18-.43.29-.7.29s-.52-.11-.71-.29l-5-5a1.003 1.003 0 011.42-1.42L8.59 14.3l10.29-10.29a1.003 1.003 0 011.42 1.42z"/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: center;
}

.checkbox-filled:checked {
  background-color: #8e173e;

  /* Keep the checkmark */
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"%3E%3Cpath d="M20.29 5.71l-11 11c-.18.18-.43.29-.7.29s-.52-.11-.71-.29l-5-5a1.003 1.003 0 011.42-1.42L8.59 14.3l10.29-10.29a1.003 1.003 0 011.42 1.42z"/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: center;
}

.checkbox-filled:disabled,
.checkbox-outlined:disabled {
  cursor: not-allowed;
}

.page-title {
  font-size: 1.25rem;
  font-weight: 500;
}

/* Modal Styles */
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0;
  gap: 5px;
  text-align: center;
}

.modal-icon {
  display: block;
  margin-inline: auto;
  margin-bottom: 10px;
}
.modal-content h3 {
  font-size: 1rem;
}

.modal-content p {
  text-align: center;
  font-weight: 300;
}

.modal-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
}
/* End of Modal Styles */

/* Error Text */
.error-text {
  font-size: 0.65rem;
  font-weight: 500;
  color: #f87171;
  margin-top: 0.25rem;
}

.react-tel-input .form-control {
  width: 100%;
  padding-block: 1rem;
}

.react-tel-input .form-control:focus {
  outline: 0 !important;
  border: none;
  box-shadow: 0 0 0 1px #8e183e;
}

.w-full {
  width: 100%;
}

.label {
  font-weight: 500;
  color: #252244;
  font-size: 0.85rem;
  display: block;
  margin-bottom: 0.25rem;
  cursor: pointer;
}

.no-item-icon {
  font-size: 3rem;
}

.no-product-image {
  height: 100px;
  width: 30%;
  background-color: #a3a3a3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.no-product-image-title {
  font-size: 1.875rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
}
