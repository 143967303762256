.filterSubheading {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #0a0a0a;
}

.filterBody {
  margin-top: 10px;
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #424242;
}
.button{
  background-color: #8E173E;
  padding: 6px 12px;
  border-radius: 5px;
  width: fit-content;
  color: white;
  cursor: pointer;
  &:hover{
    background-color: #ad1246;
  }
}