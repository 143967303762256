.menuBody {
  position: relative;
  .menuIcon {
    cursor: pointer;
  }
  .menuContent {
    background: #ffffff;
    border: 1px solid #ededed;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 2px;
    position: absolute;
    z-index: 9;
    padding: 15px 10px 5px 15px;
    right: 2rem;
    width: 258px;
  }
}

.modalBody {
  width: 500px;
  // border-radius: 12px;
  padding: 20p;
  // border: 1px solid red;
  min-height: 50vh;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  .background {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .modalHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 5px;
  }

  .modalContent {
    padding: 16px 24px;
    margin: 10px 0px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .confirmation {
      font-family: 'Sarabun';
      font-style: normal;
      font-weight: 600;
      font-size: x-large;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.011em;
      color: #0a0a0a;
      margin-bottom: 16px;
    }
    .link {
      font-family: 'Sarabun';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.011em;
      color: #424242;
    }
  }

  .modalFooter {
    margin: 10px 0px;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    .cancel {
      margin: 0px 5px;
      height: fit-content;
      min-width: fit-content;
      text-align: center;
      width: 150px;
      background: #8e173e;
      color: white;
      border-radius: 8px;
      font-weight: 600;
      padding: 10px 20px;
      height: 45px;
      // width: 120px;
      border: none;
      cursor: pointer;
    }
    .deactivate {
      margin: 0px 5px;
      height: fit-content;
      min-width: fit-content;
      text-align: center;
      width: 150px;
      border: 1px solid #8e173e;
      background: transparent;
      color: #8e173e;
      border-radius: 8px;
      font-weight: 600;
      padding: 10px 20px;
      height: 45px;
      // width: 120px;
      border: none;
      cursor: pointer;
    
      &.disabledButton {
      background: #959595;
      cursor: not-allowed;
      }
    
    }
  }
}

.linkDetails {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: -0.011em;

  /* Neutral/10 */

  color: #ffffff;
}

.qrcontainer{
  width: 80%;
  height: auto;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #8E173E;
  color: white;
}
.qrbox{
  width: auto;
  height: auto;
  padding: 20px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.iconBox{
  width: 80%;
  border: 1px solid #8E173E;
  display: flex;
  padding: 5px 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  margin: 15px 0;
  background-color: white;
}
.qrIcon{
  height: 30px;
  width: 30px;
  margin: 0px 4px;
  object-fit: contain;
}

// @media (max-width: 600px) {
//   .modalBody {
//     width: 100%; 
//   }
//   .qrbox {
//     padding: 15px; 
//   }
// }
