.topTable {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 40px;
}

.detailsHeader {
	background: #ededed;
	border-radius: 8px 8px 0px 0px;
	font-family: 'Kumbh Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	padding: 10px 20px;
}

.tableContent {
	background: #ffffff;
	padding-left: 20px;
	border-radius: 0px 0px 8px 8px;
	filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
}

.customerInfo {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	width: 50%;
	justify-content: flex-start;
	padding: 34px 0px;
}

.detailsValue {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 700;
	font-size: 10px;
	line-height: 16px;
	letter-spacing: 0.01em;
	text-transform: uppercase;
	color: #5c5b57;
}

.detailsKey {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #0a0903;
}

.transactionsHeader {
	margin-top: 10px;
	margin-bottom: 24px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.transactionsHeaderleft {
	display: flex;
	align-items: center;
	h3 {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 28px;
		display: flex;
		align-items: center;
		color: #0a0903;
		margin: 0;
		padding: 0;
		margin-right: 7px;
	}
}
.transactionsHeadercircle {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #8E173E;
	background: rgba(170, 213, 188, 0.15);
	border-radius: 40px;
	padding: 5px;
}
.transactionsHeaderright {
	button {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		text-align: center;
		color: #8E173E;
		width: 170px;
		height: 40px;
		border: 1px solid #8E173E;
		border-radius: 4px;
		outline: none;
		background-color: #ffffff;
	}
}

.detailBox {
	background: white;
	margin-bottom: 24px;
}

.detailh3 {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #0a0903;
	padding: 14px 24px;
}
.blacklist {
	display: flex;
	justify-content: flex-start;
	align-items: center;

	p {
		margin: 0;
		padding: 0;
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: #ea5851;
		margin-left: 10px;
	}
}
