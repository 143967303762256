.modalBody {
	background: white;
	// border: 1px solid red;
	height: auto;
	min-height: 100%;
	width: 100%;
	position: relative;
	border-radius: 0;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	// padding: 50px 0 0 0;
}

.modalHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	// border: 1px solid red;

.headerContent {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	width: 100%;
}
.headerContent h3 {
  font-size: x-large;
  font-weight: bold;
}
.headerContent p {
	margin-bottom: 5px;
}


.closeIcon {
  cursor: pointer;
}
}

.formSelection {
	background-color: #f9f9f9;
	padding: 20px;
	border-radius: 8px;
}

.paymentOptionListWrapper {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.radioSelection {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 15px;
	background: white;
	border: 1px solid #e0e0e0;
	border-radius: 8px;
	cursor: pointer;
	transition: background-color 0.3s ease, border-color 0.3s ease;

&:hover {
  border-color: #8e173e;
}


&.selected {
  background-color: #f5e6f0;
  border-color: #8e173e;
}

.icon {
  background: #8e173e;
  color: white;
  border-radius: 50%;
  min-height: 35px;
  min-width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radioLabel {
  flex-grow: 1;
  margin-left: 10px;
  font-weight: 500;
}

input[type="radio"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #8e173e;
  border-radius: 50%;
  background: white;

  &:checked {
	background-color: #8e173e;
  }
}
}
.radioSelection input[type="radio"] {
	appearance: none; /* Hide default radio appearance */
	width: 15px;
	height: 15px;
	border: 2px solid #8e173e; /* Purple border for unselected state */
	border-radius: 50%;
	position: relative;
	cursor: pointer;
	outline: none;
	transition: border-color 0.3s;
  }
  /* When the radio input is checked */
  .radioSelection input[type="radio"]:checked {
	background-color: #8e173e; /* Purple fill for selected state */
  }
  /* Add a ring effect around the checked radio input */
  .radioSelection input[type="radio"]:checked::after {
	content: "";
	position: absolute;
	top: -5.5px;
	left: -5.5px;
	width: 22px;
	height: 22px;
	border: 2px solid #8e173e; /* Purple ring */
	border-radius: 50%;
	opacity: 0.4; /* Semi-transparent ring */
  }
.modalFooter {
	display: flex;
	justify-content: end;
	margin-top: 20px;
}
.cancelButton {
	margin: 0px 5px;
	height: fit-content;
	min-width: fit-content;
	width: 150px;
	border: 1px solid #8e173e;
	background: transparent;
	color: #8e173e;
	border-radius: 8px;
	font-weight: 600;
	padding: 10px 20px;
	height: 45px;
	// width: 120px;
	border: none;
	cursor: pointer;

  &.disabledButton {
	background: #959595;
	cursor: not-allowed;
  }

}
.initiateButton {
	margin: 0px 5px;
	background: #8e173e;
	border: 1px solid #8e173e;
	color: white;
	border-radius: 8px;
	padding: 4px;
	height: 45px;
	min-width: fit-content;
	width: 150px;
	font-weight: 600;
	border: none;
	cursor: pointer;
  
	&.disabledButton {
	  background: #959595;
	  border: 2px solid #959595;
	  cursor: not-allowed;
	}
}
.modalContent{
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.qrcontainer{
	width: 80%;
	height: auto;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	background-color: #8E173E;
	color: white;
  }
  .qrbox{
	width: auto;
	height: auto;
	padding: 20px;
	border-radius: 20px;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  .iconBox{
	width: 80%;
	border: 1px solid #8E173E;
	display: flex;
	padding: 5px 0;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 30px;
	margin: 15px 0;
	background-color: white;
  }
  .qrIcon{
	height: 30px;
	width: 30px;
	margin: 0px 4px;
	object-fit: contain;
  }
  