.modalupload {
	height: 315px;
	background: #ffffff;
	border: 1px solid #2b9558;
	border-radius: 7px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	button {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		text-align: center;
		color: #8E173E;
		width: 160px;
		height: 40px;
		border: 1px solid #8E173E;
		border-radius: 4px;
		margin-top: 50px;
		background-color: #ffffff;
	}

	p {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: #4b492b;
		margin: 0;
		padding: 0;
		margin-top: 15px;
	}
}