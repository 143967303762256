.modalBody {
  width: 444px;
  border-radius: 12px;

  .background {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .modalHeader {
    background: #8e173e;
  }
}

.businessForm {
  width: 100% !important;
  // margin-top: 24px;
  margin-right: auto;
  padding: 0 24px;

  & > form {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    padding-bottom: 24px;
    height: 360px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    & > div {
      &:not(:first-child) {
        margin-top: 27px;
      }
    }
  }
}

.linkDetails {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.011em;
  color: #ffffff;
}

.modalContent {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 0 24px;

  .modalDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    border-bottom: 1px solid #e0e0e0;
  }
}

.modalFooter {
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #f5f5f5;

  .cancel {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #0a0a0a;
    padding: 8px 10px;
    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    cursor: pointer;
  }

  .deactivate {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #ffffff;
    padding: 8px 12px;
    background: #8e173e;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    cursor: pointer;
    margin-left: 16px;
  }

  .notClickable {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #9e9e9e;
    background: #ededed;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    margin-left: 16px;
    padding: 8px 12px;
  }
}

.inputLabel {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.006em;

  /* Neutral/90 */

  color: #424242;
}

.modalselect {
  width: 100%;
}

.select {
  width: 100%;
}

.buttonSwitch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}

.buttonSwitchButton {
  flex: 1;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.011em;
  padding: 8px 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  border: none;
  margin: 0 5px;
}

#buttonSwitchButton {
  color: white;
  background-color: #8e173e;
}

.listBen {
  padding: 10px;
  border-radius: 20px;
  border: 1px solid black;
  color: black;
  background-color: white;
  margin-right: 10px;
  cursor: pointer;
}

.listBenWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.otpWrapper {
  display: flex;
  flex-direction: column;

  button {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #8e173e;
    padding: 4px 6px;
    background: #fff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 20px;
  }
}
