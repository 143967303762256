.preferencesBody {
}

.accounts {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 30px;
	width: 100%;
}
.accountInfo {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
}
.accountEarnings {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #616161;
	margin-top: 4px;
}

.secondhand {
	min-width: 682px;
	background: #ffffff;
	border-radius: 8px;
	padding: 24px;
}

.paymentMethods {
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	padding: 24px;
	width: 100%;
	margin-top: 30px;
	.accountInfo {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #0a0a0a;
	}
	.accountEarnings {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #616161;
		margin-top: 4px;
	}
}

.radioSelection {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.radioLabel {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: -0.011em;
	color: #424242;
}

.radioSelection_one {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 25px;
	padding-bottom: 10px;
	border-bottom: 1px solid #e0e0e0;
	.radioLabel {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		letter-spacing: -0.011em;
		color: #424242;
	}
}

.paymentList {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	.checkboxContent {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: row;
		width: 100%;
		border-bottom: 1px solid #e0e0e0;
		padding-top: 8px;
		padding-bottom: 8px;
	}
	.checkboxContentFa {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: row;
		width: 100%;
	}
	// .checkboxContent:nth-child(n + 1):nth-child(-n + 3) {
	// 	border-bottom: 1px solid #e0e0e0;
	// 	padding-top: 8px;
	// 	padding-bottom: 8px;
	// }
	.checkboxLabel {
		font-family: 'Sarabun';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		letter-spacing: -0.011em;
		color: #424242;
	}
}


/*Mobile View */
@media (max-width: 1024px){
	.accounts{
		flex-direction: column;
	}
}
